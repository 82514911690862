import './css/index.scss'

import $ from 'jquery'
import {
  setupCookiePolicy
} from './cookies'

const setupMenu = () => {
  const breakpoint = 991;
  const menu = $(".main-menu");
  const menu__lu = $(".main-menu lu");
  const menuToogle = $(".main-menu-toogle");
  const openClass = "opened";
  const vertical = $(".vertical-display");
  const hideMenu = () => {
    menuToogle.removeClass(openClass).attr('aria-expaned', false);
    menu.slideUp();
  };
  const showMenu = () => {
    menuToogle.addClass(openClass).attr('aria-expaned', true);;
    menu.slideDown();
  };

  const onResize = () => {
    if (window.innerWidth <= breakpoint) {
      hideMenu();
      menuToogle.slideDown();
    } else {
      showMenu();
      menuToogle.slideUp();
    }
  };

  menuToogle.on("click", function (e) {
    e.preventDefault();
    if (menuToogle.hasClass(openClass)) {
      hideMenu();
      menu__lu.removeClass(vertical); //not working                                                     
    } else {
      showMenu();
      menu__lu.addClass(vertical); //not working
    }
  });

  $(window).on("resize", e => {
    onResize();
  });
  // on load
  onResize();

};



$(function () {
  setupMenu()
  setupCookiePolicy(window.COOKIE_POLICY_URL)
})